import gsap from "gsap";

export default function Video() {

    const tl_video = gsap.timeline({
    })

    tl_video.to(".video", {
        opacity: 1,
        scale: 1
    });

}