import gsap from "gsap";
import ScrambleTextPlugin from "gsap/ScrambleTextPlugin";
gsap.registerPlugin(ScrambleTextPlugin);

export default function Header() {
    
    const tl_header = gsap.timeline({
        delay: 1,
    })

    tl_header.to(".header-title .char", {
        y: 0,
        stagger: 0.13,
        force3D: true
    });

    tl_header.to(".header-desc .char, .header-desc2 .char", {
        y: 0,
        duration: 0.8,
        stagger: 0.03,
        force3D: true
    }, "-=1.2");

    const tl_descscramble = gsap.timeline({
        delay: 5,
        repeat: -1,
        repeatDelay: 1,
    });

    tl_descscramble.to(".header-desc2", {
        scrambleText: {
            text: "rencontrer, découvrir, inventer",
            speed: 0.5
        }
    });

    tl_descscramble.to(".header-desc2", {
        scrambleText: {
            text: "会う、発見する、発明する",
            speed: 0.5
        }
    }, "+=1.0");

    tl_descscramble.to(".header-desc2", {
        scrambleText: {
            text: "to meet, to discover, to invent",
            speed: 0.5
        }
    }, "+=1.0");


    // const tl_header_scroll = gsap.timeline({
    //     scrollTrigger: {
    //         trigger: "#header",
    //         start: "top top",
    //         end: "bottom top",
    //         endTrigger: "#footer",
    //         scrub: true,
    //         pin: ".header-title",
    //         pinSpacing: false
    //     }
    // })

    // tl_header_scroll.to(".header-title", {
    //     scale: 0.1,
    //     yPercent: -50
    // });
}