// Dependency
import {
    Utility
} from "./config/animation";
import Smoothscroll from './locomotive';
import Default from './config/default';

// import Preload from "./preload";
import Header from "./header";
import Intro from "./intro";
// import Imgeffect from "./aladino";
import End from "./end";
import Universal from "./universalanim";
import Video from "./videoplayer";

function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

export default function Init() {
    Default();
    Utility();

    // Preload();

    Header();
    Smoothscroll();
    Intro();
    Universal();
    End();
    Video();

}

docReady(() => {
    Init();
});