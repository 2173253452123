import Swiper, { Navigation } from "swiper";
Swiper.use(Navigation);
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { anim_fadeBottom, anim_revealBottom } from "./config/animation";

export default function Intro() {

    // Circular
    gsap.to(".intro-image-txt-circular", {
        duration: 10,
        ease: "none",
        rotation: 360,
        repeat: -1
    });

    const swiper = new Swiper('.swiper-container', {
        navigation: {
            nextEl: '.slider-button-next',
            prevEl: '.slider-button-prev',
        },
    });

    gsap.set(".swiper-slide", {
        scale: 0.85
    });

}