import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import imagesLoaded from 'imagesloaded';

export default function Intro() {

    const elLoad = document.querySelector("#end");
    const imgLoad = imagesLoaded(elLoad)
    imgLoad.on('always', () => {
        // Image
        const tl_end = gsap.timeline({
            scrollTrigger: {
                trigger: "#end"
            }
        })

        tl_end.from(".end-inline-title .line", {
            yPercent: 100,
            stagger: 0.18,
            force3D: true
        });
    });


}