import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { anim_revealBottom, anim_fadeBottom } from "./config/animation";

export default function Universal() {

    // Title
    gsap.utils.toArray(".reveal-char").forEach((el, i) => {
        const char = el.querySelectorAll(".char");
        const tl_universal = gsap.timeline({
            scrollTrigger: {
                trigger: el
            }
        })
        
        tl_universal.from(char, anim_revealBottom);
    });

    gsap.utils.toArray(".reveal-line").forEach((el, i) => {
        const line = el.querySelectorAll(".line");
        const tl_universal = gsap.timeline({
            scrollTrigger: {
                trigger: el
            }
        })
        
        tl_universal.from(line, {
            yPercent: 100,
            stagger: 0.18,
            force3D: true
        });
    });

    gsap.utils.toArray(".fade-bottom").forEach((el, i) => {
        const tl_universal = gsap.timeline({
            scrollTrigger: {
                trigger: el
            }
        })
        
        tl_universal.from(el, anim_fadeBottom);
    });

    
}